<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              {{ title }}
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              X
            </span>
          </v-col>
        </v-row>
        <template v-if="dataModal !== null">
          <v-row
            v-for="(v, i) in dataModal"
            :key="'data-' + i"
          >
            <v-col class="d-flex justify-space-between">
              <span>
                {{ v.tab_main_title || v.name }}
              </span>
              <span
                style="cursor: pointer; color: #2B7DEC;"
                @click="navDetail(v, i)"
              >
                Lihat Struktur Lengkapnya
                <v-icon color="#2B7DEC">
                  mdi-chevron-right
                </v-icon>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Array,
        default: null,
      },
      title: {
        type: String,
        default: 'Badan Pengurus Daerah',
      },
    },

    data: () => ({
      modal: false,
      cbVal: '',
      items: [],
    }),

    computed: {},

    watch: {
    },

    created () {
    },

    methods: {
      close () {
        this.$emit('close')
      },

      navDetail (p, p2) {
        // console.log(p)
        // console.log(this.$route.name.toLowerCase())
        const key = this.$route.name.toLowerCase()
        if (key === 'strukturbpp') {
          this.$router.push({ name: 'StrukturBpd', params: { index: p2, id: p.tab_main_hash } })
        }
        this.$emit('set', p)
        this.close()
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
