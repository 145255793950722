<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #f2f2f2"
  >
    <template v-if="tabs !== null">
      <v-row>
        <v-col class="txt26-black">
          {{ title }}
        </v-col>
      </v-row>

      <v-row class="px-3">
        <v-col v-for="i in 3" :key="'tab-' + i" class="px-0" cols="4">
          <div
            :style="getStyle(i)"
            class="d-flex align-center elevation-0 rounded-lg"
            :class="i === 2 ? 'mx-2' : ''"
            @click="chState(i)"
          >
            <div class="flex-grow-1 text-center">
              <template v-if="i === 1">
                {{ tabs.tab_main_title }}
              </template>
              <template v-if="i === 2">
                {{ tabs.tab_hp_title }}
              </template>
              <template v-if="i === 3">
                {{ tabs.tab_pt_title }}
              </template>
            </div>
          </div>
        </v-col>
      </v-row>

      <template>
        <v-row>
          <v-col v-for="(v, i) in tabs.addon_info" :key="'add-on' + i">
            <v-card class="px-6 py-4">
              <div class="txt16-gray50-bold">
                {{ v.title }}
              </div>
              <div class="d-flex justify-space-between">
                <span class="txt44-green2-bold">
                  {{ v.total }}
                </span>
                <span
                  class="d-flex align-end"
                  style="cursor: pointer; color: #2b7dec"
                  @click="i === 0 ? showModal(v) : showModalUniversity(v)"
                >
                  Lihat Struktur Lengkapnya
                  <v-icon color="#2B7DEC"> mdi-chevron-right </v-icon>
                </span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-row
      v-for="(v, i) in tabContent[activeState].group"
      :key="'content-' + i"
    >
      <v-col>
        <span class="txt20-gray50-bold">
          {{ v.name || "-" }}
        </span>
        <v-card class="mt-3 px-4 py-5">
          <v-row v-for="(x, y) in v.structure" :key="'struct-' + y">
            <v-col class="txt16-gray50" cols="6" sm="4">
              {{ x.position_string || "-" }}
            </v-col>
            <v-col
              v-if="isAllowModify || x.can_edit"
              class="d-flex txt16-gray50-bold"
              cols="6"
              sm="3"
              
            >
              {{ x.member_name || "-" }}
              <div
                :id="x.position_hash"
                class="icon-container ml-2 pl-1"
                @click="dialog = true; show(v, x, tabContent[activeState].entity_hash)"
              >
              <!-- @mouseenter="show(v, x, tabContent[activeState].entity_hash)" -->
                <v-icon color="#FFC709" size="18"> mdi-pencil </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <modal-update
      :show="dialog"
      :data-modal="dtUpdate"
      @close="dialog = false"
      @init="preInitialize"
    />

    <template v-if="bpc">
      <modal-detail
        :show="dialog2"
        :data-modal="dtBpc"
        :title="'Badan Pengurus Cabang'"
        @close="dialog2 = false"
        @set="setBpd"
      />
    </template>
    <template v-else>
      <modal-detail
        :show="dialog2"
        :data-modal="dtDetail"
        @close="dialog2 = false"
        @set="setBpd"
      />
    </template>

    <v-dialog v-model="dialog3" max-width="600" persistent>
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Badan Pengurus Universitas </span>
            <span style="cursor: pointer" @click="dialog3 = false"> X </span>
          </v-col>
        </v-row>
        <template v-if="university">
          <v-row
            v-for="(v, i) in university.modal_list_data"
            :key="'data-' + i"
          >
            <v-col class="d-flex justify-space-between">
              <span>
                {{ v.tab_main_title || v.name }}
              </span>
              <span
                v-if="v.ent_hash"
                style="cursor: pointer; color: #2b7dec"
                @click="navDetail(v)"
              >
                Lihat Struktur Lengkapnya
                <v-icon color="#2B7DEC"> mdi-chevron-right </v-icon>
              </span>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ModalUpdate from "./ModalUpdate.vue";
import ModalDetail from "./ModalDetail.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalUpdate,
    ModalDetail,
  },

  data: () => ({
    title: "Badan Pengurus Pusat",
    tab: null,
    tabs: null,
    tabContent: {
      1: [],
      2: [],
      3: [],
    },
    activeState: 1,
    dialog: false,
    dialog2: false,
    dialog3: false,
    dtUpdate: null,
    dtDetail: null,
    dtBpc: null,
    pageState: "bpp",
    bpd: null,
    bpc: false,
    isAllowModify: false,
    university: null,
  }),

  computed: {},

  watch: {
    $route() {
      this.preInitialize();
      //   console.log(to)
      //   if (to.params.key === 'bpd') this.title = 'Badan Pengurus Daerah'
      //   if (to.params.key === 'bpc') this.title = 'Badan Pengurus Cabang'
    },
  },

  created() {
    this.preInitialize();
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const userMenus = userInfo.menu_function;
    if (userMenus.includes("ORGSTRUCT_MOD")) this.isAllowModify = true;
  },

  methods: {
    showModal(p) {
      // console.log(p)
      this.dtBpc = p.modal_list_data;
      this.dialog2 = true;
    },

    showModalUniversity(p) {
      // console.log(p)
      this.university = p;
      this.dialog3 = true;
    },

    navDetail(p) {
      // console.log(p)
      if (p === "bpd") {
        this.$router.replace({
          name: "StrukturOrganisasiDetail",
          params: { key: p },
        });
        this.pageState = p;
        this.preInitialize();
      }
    },

    setBpd(p) {
      // console.log(p)
      // this.pageState = 'bpd'
      // this.bpd = p
      this.tabs = p;
      // this.bpc = true
      // this.getBpc(this.bpd.tab_main_hash)
      // this.getStructure(this.bpd.tab_main_hash, 1)
      // this.getStructure(this.bpd.tab_hp_hash, 2)
      // this.getStructure(this.bpd.tab_pt_hash, 3)
    },

    chState(p) {
      this.activeState = p;
    },

    // hide(p) {
    //   document.getElementById(p.position_hash).style.display = "none";
    // },

    show(p, p2, p3) {
      p.ent_hash = p3;
      p.sub = p2;
      this.dtUpdate = p;
      document.getElementById(p2.position_hash).style.display = "block";
    },

    getStyle(p) {
      let v = "";
      if (p === this.activeState) {
        v += "font-family: Lato;";
        v += "background: #FFFFFF;";
        v += "color: #36AC87;";
        v += "font-size: 16px;";
        v += "font-weight: bold;";
        v += "height: 50px;";
        v += "cursor: pointer;";
      } else {
        v += "font-family: Lato;";
        v += "font-size: 16px;";
        v += "background: #E5E5E5;";
        v += "color: #808080;";
        v += "height: 50px;";
        v += "cursor: pointer;";
      }

      return v;
    },

    preInitialize() {
      const key = this.$route.name.toLowerCase();
      if (key === "strukturbpp") {
        this.getBpp();
        this.getBpd();
      }
      if (key === "strukturbpd") {
        this.bpc = true;
        this.getBpd();
      }
      if (key === "strukturbpc") {
        this.bpc = true;
        this.getBpd();
      }
    },

    initialize(p, p2) {
      const reqBody = {
        ent_hash: p,
      };
      axios
        .post("/v1/admin/structure-org/get-struct", reqBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.items[p2].content = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getBpp() {
      axios
        .post("/v1/admin/structure-org/get-bpp", {})
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.tabs = res.data.data;
            this.getStructure(this.tabs.tab_main_hash, 1);
            this.getStructure(this.tabs.tab_hp_hash, 2);
            this.getStructure(this.tabs.tab_pt_hash, 3);
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getBpd() {
      axios
        .post("/v1/admin/structure-org/get-bpd", {})
        .then((res) => {
          if (res.data.status === 200) {
            this.dtDetail = res.data.data.bpd.list;
            const key = this.$route.name.toLowerCase();
            if (key === "strukturbpd") {
              const i = this.$route.params.index;
              const id = this.$route.params.id;
              this.title = this.dtDetail[i].name;
              this.tabs = this.dtDetail[i];
              this.getStructure(this.dtDetail[i].tab_main_hash, 1);
              this.getStructure(this.dtDetail[i].tab_hp_hash, 2);
              this.getStructure(this.dtDetail[i].tab_pt_hash, 3);
              this.getBpc(id);
            }
            if (key === "strukturbpc") {
              const id = this.$route.params.id;
              // this.title = this.dtDetail[i].name
              // this.tabs = this.dtDetail[i]
              this.getBpc(id);
              // this.getStructure(this.dtDetail[i].tab_main_hash, 1)
              // this.getStructure(this.dtDetail[i].tab_hp_hash, 2)
              // this.getStructure(this.dtDetail[i].tab_pt_hash, 3)
            }
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getBpc(p) {
      const reqBody = {
        bpd_hash: p,
      };
      axios
        .post("/v1/admin/structure-org/get-bpc", reqBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data.bpc.list)
            const key = this.$route.name.toLowerCase();
            if (key === "strukturbpd") {
              this.dtBpc = res.data.data.bpc.list;
            } else {
              this.dtBpc = res.data.data.bpc.list;
              const i = this.$route.params.index;
              this.title = this.dtBpc[i].name;
              this.tabs = this.dtBpc[i];
              this.getStructure(this.dtBpc[i].tab_main_hash, 1);
              this.getStructure(this.dtBpc[i].tab_hp_hash, 2);
              this.getStructure(this.dtBpc[i].tab_pt_hash, 3);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getStructure(p, p2) {
      const reqBody = {
        ent_hash: p,
      };
      axios
        .post("/v1/admin/structure-org/get-struct", reqBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data.bpc.list)
            this.tabContent[p2] = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt20-gray50-bold {
  @extend .h-5;
  font-weight: bold;
  color: $gray-50;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  font-weight: bold;
  color: $gray-50;
}

.txt26-black {
  @extend .h-4;
  font-size: 26px;
}

.txt44-green2-bold {
  @extend .h-2;
  font-weight: bold;
  color: $green-2;
}

.icon-container {
  width: 28px;
  height: 28px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.hide {
  display: none;
}
</style>
