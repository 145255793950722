<template>
  <div>
    <v-dialog v-model="show" max-width="600" persistent>
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Ubah Pengurus </span>
            <span style="cursor: pointer" @click="close"> X </span>
          </v-col>
        </v-row>
        <template v-if="dataModal !== null">
          <v-row>
            <v-col cols="4"> Posisi </v-col>
            <v-col cols="8">
              {{ dataModal.sub.position_string || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> Pengurus saat ini </v-col>
            <v-col cols="8">
              {{ dataModal.sub.member_name || "-" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> Ubah Pengurus </v-col>
            <v-col cols="8">
              <v-combobox
                v-model="cbVal"
                :items="items"
                color="#2E976C"
                item-color="#2E976C"
                clearable
                solo
                item-text="member_name"
                item-value="member_hash"
                placeholder="Cari nama..."
                @update:search-input="getMember"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                color="#2E976C"
                class="mx-1 text-capitalize"
                style="font-weight: bold; color: white"
                @click="modal = true"
              >
                Simpan
              </v-btn>
              <v-btn
                color="#CCCCCC"
                class="mx-1 text-capitalize"
                style="font-weight: bold; color: white"
                @click="close"
              >
                Batal
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
    <modal-konfirmasi :show="modal" @submit="confirm" />
  </div>
</template>

<script>
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalKonfirmasi,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataModal: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    modal: false,
    cbVal: "",
    items: [],
  }),

  computed: {},

  watch: {},

  created() {},

  methods: {
    close() {
      this.$emit("close");
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    getMember(e) {
      if (e.length < 2) return;

      const requestBody = {
        search: e,
      };

      axios
        .post("/v1/general/get-member", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.items = res.data.data.member.list;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    submit() {
      // console.log(e)
      const reqBody = {
        ent_hash: this.dataModal.ent_hash,
        position_hash: this.dataModal.sub.position_hash,
        member_hash: this.cbVal.member_hash,
        create_new: this.dataModal.member_name ? 0 : 1,
        board_hash: this.dataModal.sub.board_hash,
      };

      axios
        .post("/v1/admin/structure-org/update-struct", reqBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$emit("init");
            this.$toast.success(res.data.message);
            this.close();
          }
        })
        .catch((e) => {
          // console.log(e.response.data.error)
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
